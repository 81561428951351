import React, { useState } from 'react';

const backend_root = process.env.REACT_APP_BACKEND_ROOT || "http://localhost:5000";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [result, setResult] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Fetching");
    // Replace with your actual API endpoint for login
    const response = await fetch(backend_root + '/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    const jsonResponse = await response.json();
    const finalResponse = JSON.stringify(jsonResponse);
    if (response.ok) {
      // Redirect or handle success
      // window.location.href = '/trade';
      setResult("Ok " + finalResponse);
    } else {
      // Handle login failure
      console.error('Login failed');
      
      setResult("Fail " + finalResponse);
    }
  };
  
  const handleLogout = async () => {
    const response = await fetch(backend_root + '/logout', {
      method: 'GET'
    });

    if (response.ok) {
      // Redirect or handle success
      console.error('Logout succeeded');
      window.location.href = '/login';
    } else {
      // Handle login failure
      console.error('Logout failed');
      // window.location.href = '/';
    }
  };

  return (
    <div>
        <form onSubmit={handleSubmit}>
        <label>
            Username:
            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </label>
        <br />
        <label>
            Password:
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </label>
        <br />
        <button type="submit">Login</button>
        </form>
    

        <button onClick={handleLogout}>Logout</button>

        <p>{result}</p>
    </div>
  );
};

export default Login;
