import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AddTrade from './AddTrade';
import HomePage from './HomePage';
import Login from './Login'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path="/trade" element={<AddTrade />} />

        <Route path="/login" element={<Login />} />

      </Routes>
    </Router>
  );
}

export default App;
