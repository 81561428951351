import React from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';

const HomePage = () => {
  return (
    <div>
      <nav>
        {/* Links to navigate between pages */}
        <Link to="/">Home</Link>{' '}
        <Link to="/trade">Trades</Link>{' '}
        <Link to="/login">Login</Link>
      </nav>
      <h2>Welcome!</h2>
      <p>Website in construction</p>
    </div>
  );
};

export default HomePage;
